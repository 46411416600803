/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */

import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import {
    enableMetafieldsOnShopify,
    formatConfigForSave,
} from 'pages/Settings/Customizations/utils'
import { Parse } from 'services/parse'
import trackCustomizations from 'services/user-tracking/track-customizations-change'
import trackPaymentMethodChange from 'services/user-tracking/track-payment-methods'
import trackSettings from 'services/user-tracking/track-settings-change'
import {
    Settings,
    SettingsFormattedResponse,
    SettingsParseResponse,
} from 'types/api-types'
import {
    formatSettingsResponse,
    formatSquareImages,
    getUpdatedThemeOptions,
    shapePaymentMethodsForParse,
} from './helpers'
import {
    CartBannerFieldsDisabled,
    CustomizationsReturn,
    UpdateCustomizations,
} from 'types/forms/settings-types'
import {
    AppSettingsForm,
    CartBannerFieldsEnabled,
    CartBannerFieldsGiftEngineEnabled,
    GiftEngineEnabled,
    GiftEngineFieldsDisabled,
} from 'types/forms/settings-types'
import { RootState } from 'store'
import { ThemeOptions } from 'types/dashboard-types'
import { updateProductImages } from 'store/Themes/themes-slice'

interface SettingsState {
    data: Settings | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

const initialState: SettingsState = {
    data: {},
    loading: 'idle',
    error: null,
    initialized: false,
}

interface SettingsState {
    data: Settings | Record<string, never>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
}

export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async () => {
        const settingsParseObject = (await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()) as SettingsParseResponse

        return formatSettingsResponse(
            settingsParseObject
        ) as SettingsFormattedResponse
    }
)

export const updateSettingsProperty = createAsyncThunk(
    'settings/updateSettings',
    async ({ key, value }, { rejectWithValue }) => {
        const settingsParseObj = Parse.User.current().get('app').get('settings')
        try {
            settingsParseObj.set(key, value)
            const response = await settingsParseObj.save()
            return response.attributes[key]
        } catch (err) {
            return rejectWithValue(
                'There was an error updating Parse Settings Object'
            )
        }
    }
)

export type UpdateAppSettings = {
    values: AppSettingsForm
    initialValues: AppSettingsForm
}

export const updateAppSettings = createAsyncThunk(
    'settings/updateAppSettings',

    async ({ values, initialValues }: UpdateAppSettings, { getState }) => {
        const { settings, app } = getState() as RootState

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call,
        const settingsParseObject = (await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()) as SettingsParseResponse

        let shapedCartBanner = { bannerType: 'imageBanner' }
        let shapedGiftEngine = {}
        if (
            values?.cartBannerFields?.bannerType === 'freeShippingLabelBanner'
        ) {
            shapedCartBanner = { ...values?.cartBannerFields }
        } else if (values?.giftEngineFields?.enabled) {
            shapedCartBanner = {
                imageUrl: values?.giftEngineFields?.imageUrl,
                destination: 'giftPopupModal',
                bannerType: 'imageBanner',
            } as CartBannerFieldsGiftEngineEnabled
            shapedGiftEngine = {
                enabled: true,
                imageUrl: values?.giftEngineFields?.imageUrl,
                collectionId: values?.giftEngineFields?.collectionId,
                metafieldNamespace: 'tapcart',
                metafieldKey: 'gwp-threshold',
            } as GiftEngineEnabled
        } else if (values?.cartBannerFields?.enabled) {
            shapedCartBanner = {
                enabled: true,
                imageUrl: values?.cartBannerFields?.imageUrl,
                destination: 'static',
                bannerType: 'imageBanner',
            } as CartBannerFieldsEnabled
            shapedGiftEngine = {} as GiftEngineFieldsDisabled
        } else if (
            !values?.cartBannerFields?.enabled &&
            !values?.giftEngineFields?.enabled
        ) {
            shapedCartBanner = {
                ...shapedCartBanner,
                enabled: false,
            } as CartBannerFieldsDisabled
        }

        const updatedThemeOptions = {
            ...settings.data.themeOptions,
            ...values,
            discounts: String(values.discounts),
            cartBannerFields: shapedCartBanner,
            giftEngineFields: shapedGiftEngine,
        }

        await Parse.Cloud.run('modifyInstallmentPayment', {
            appId: app.data.id,
            installmentPayment: values.installmentPayment || 'none',
        })

        if (values?.giftEngineFields?.enabled) {
            await Parse.Cloud.run('saveGiftEngine', {
                appId: app.data.id,
                giftEngine: shapedGiftEngine,
            })
        }
        const shapedPaymentMethods = shapePaymentMethodsForParse(
            values.paymentMethods
        )
        const initialShapedPaymentMethods = shapePaymentMethodsForParse(
            initialValues.paymentMethods
        )

        settingsParseObject.set('paymentMethods', shapedPaymentMethods)
        settingsParseObject.set('themeOptions', updatedThemeOptions)

        const settingsResponse = await settingsParseObject.save()

        trackSettings({
            originalAppSettings: initialValues,
            appSettings: values,
        })

        trackPaymentMethodChange({
            originalPaymentMethods: initialShapedPaymentMethods,
            paymentMethods: shapedPaymentMethods,
        })

        return formatSettingsResponse(settingsResponse)
    }
)
export const updateDesignSettings = createAsyncThunk(
    'settings/updateDesignSettings',
    async ({ multiBlockSections, blocks }, { getState }) => {
        const settingsParseObj = await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()

        settingsParseObj.set('multiBlockSections', multiBlockSections)
        settingsParseObj.set('blocks', blocks)

        const response = await settingsParseObj.save()
        return formatSettingsResponse(response)
    }
)

export const updateDynamicProductSettings = createAsyncThunk(
    'settings/updateDynamicProductSettings',
    async (updatedValues) => {
        const settingsParseObj = await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()

        settingsParseObj.set('config', updatedValues)
        await settingsParseObj.save()
    }
)

export const updateCustomizations = createAsyncThunk(
    'settings/updateCustomizations',
    async (
        { values, initialValues }: UpdateCustomizations,
        { getState, dispatch }
    ): Promise<CustomizationsReturn> => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call,

        // try to save product images first and return error if they fail
        if (values.productImages) {
            try {
                const response = await dispatch(
                    updateProductImages(values.productImages)
                ).unwrap()

            } catch (err) {
                throw new Error(err)
            }
        }

        const settingsParseObject = (await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()) as SettingsParseResponse
        const { settings } = getState() as RootState

        const settingsData = settings?.data

        const squareImages = formatSquareImages(values.squareImages)

        const themeOptionsData = {
            ...settingsData?.themeOptions,
            giftCardMessage: values.giftCardMessage || null,
            giftCardBackground: values.giftCardBackground,
            discountMessage: values.discountMessage || null,
            discountBackground: values.discountBackground,
            favoriteMessage: values.favoriteMessage || null,
            favoritesBackground: values.favoritesBackground,
            forgotPasswordMessage: values.forgotPasswordMessage || null,
            forgotPasswordBackground: values.forgotPasswordBackground,
            squareImages,
        } as ThemeOptions

        const config = formatConfigForSave(settingsData?.config, {
            metafields: values.metafields,
        })

        settingsParseObject.set('themeOptions', themeOptionsData)
        settingsParseObject.set('config', config)
        settingsParseObject.set('filters', values.filters)

        trackCustomizations({
            originalCustomizations: cloneDeep(initialValues),
            customizations: values,
        })

        await enableMetafieldsOnShopify(config?.shopify?.metafields ?? [])

        await settingsParseObject.save()

        return {
            themeOptions: themeOptionsData,
            config,
            filters: values.filters,
        }
    }
)

export const updateCollectionsPage = createAsyncThunk(
    'settings/upateCollectionsPage',
    async ({ collections, themeOptions }) => {
        const settingsParseObj = await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()

        settingsParseObj.set('navigation', collections)
        settingsParseObj.set('themeOptions', themeOptions)
        await settingsParseObj.save()

        return { collections, themeOptions }
    }
)

export const updateThemeOptions = createAsyncThunk(
    'settings/updateThemeOptions',
    async (updatedValues) => {
        const settingsParseObj = await Parse.User.current()
            .get('app')
            .get('settings')
            .fetch()

        settingsParseObj.set('themeOptions', updatedValues)
        await settingsParseObj.save()
        return updatedValues
    }
)

export const updateInstallmentPayment = createAsyncThunk(
    'settings/updateInstallmentPayment',
    async (updatedValues, { getState }) => {
        const { app } = getState()
        const response = await Parse.Cloud.run('modifyInstallmentPayment', {
            appId: app.data.id,
            installmentPayment: updatedValues.installmentPayment || 'none',
        })
        return response
    }
)

export const updateGiftEngine = createAsyncThunk(
    'settings/updateGiftEngine',
    async (updatedValues, { getState }) => {
        const { app } = getState()
        const response = await Parse.Cloud.run('saveGiftEngine', {
            appId: app.data.id,
            giftEngine: updatedValues.giftEngineFields,
        })
        return response
    }
)

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            state.data = action.payload.result
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(fetchSettings.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateSettingsProperty.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateSettingsProperty.fulfilled, (state, action) => {
            const { key } = action.meta.arg
            state.data[key] = action.payload
            state.loading = 'fufilled'
            state.error = null
        })
        builder.addCase(updateSettingsProperty.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(updateAppSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateAppSettings.fulfilled, (state, action) => {
            state.data = action.payload.result
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(updateAppSettings.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(updateInstallmentPayment.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateInstallmentPayment.fulfilled, (state, action) => {
            state.data.installmentPayment = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(updateInstallmentPayment.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(updateGiftEngine.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateGiftEngine.fulfilled, (state, action) => {
            state.data.themeOptions.giftEngineFields = action.payload
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(updateGiftEngine.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(updateCustomizations.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateCustomizations.fulfilled, (state, action) => {
            state.data.themeOptions = action.payload.themeOptions
            state.data.config = action.payload.config
            state.data.filters = action.payload.filters
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true
        })
        builder.addCase(updateCustomizations.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })

        builder.addCase(updateDesignSettings.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateDesignSettings.fulfilled, (state, action) => {
            state.loading = 'fufilled'
            state.error = null
            state.initialized = true

            state.data.blocks = action.payload.result.blocks
        })
        builder.addCase(updateDesignSettings.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateCollectionsPage.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateCollectionsPage.fulfilled, (state, action) => {
            state.data.themeOptions = action.payload.themeOptions
            state.data.navigation = action.payload.collections
        })
        builder.addCase(updateCollectionsPage.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
        builder.addCase(updateThemeOptions.pending, (state) => {
            state.loading = 'pending'
        })
        builder.addCase(updateThemeOptions.fulfilled, (state, action) => {
            state.data.themeOptions = action.payload
        })
        builder.addCase(updateThemeOptions.rejected, (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        })
    },
})

export default settingsSlice.reducer
