import React from 'react'

const PostPurchaseIcon = ({ color = '#111111', iconLeft }) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M4 28V8.5C4 6.01471 6.01472 4 8.5 4H23.5C25.9853 4 28 6.01471 28 8.5V17.5C28 19.9853 25.9853 22 23.5 22H10L4 28" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
	<path d="M18.2218 10.9044C18.9121 10.9044 19.4718 10.3447 19.4718 9.65437C19.4718 8.96402 18.9121 8.40437 18.2218 8.40437V10.9044ZM16.5551 12.9877V11.7377V12.9877ZM16.5551 16.321V17.571V16.321ZM13.7773 15.071C13.087 15.071 12.5273 15.6307 12.5273 16.321C12.5273 17.0114 13.087 17.571 13.7773 17.571V15.071ZM17.2493 16.321C17.2493 15.6307 16.6896 15.071 15.9993 15.071C15.3089 15.071 14.7493 15.6307 14.7493 16.321H17.2493ZM14.7493 17.9877C14.7493 18.6781 15.3089 19.2377 15.9993 19.2377C16.6896 19.2377 17.2493 18.6781 17.2493 17.9877H14.7493ZM17.2493 7.98771C17.2493 7.29735 16.6896 6.73771 15.9993 6.73771C15.3089 6.73771 14.7493 7.29735 14.7493 7.98771H17.2493ZM14.7493 9.65438C14.7493 10.3447 15.3089 10.9044 15.9993 10.9044C16.6896 10.9044 17.2493 10.3447 17.2493 9.65438H14.7493ZM18.2218 8.40437H15.444V10.9044H18.2218V8.40437ZM15.444 8.40437C13.8332 8.40437 12.5273 9.71021 12.5273 11.321H15.0273C15.0273 11.0909 15.2139 10.9044 15.444 10.9044V8.40437ZM12.5273 11.321C12.5273 12.9319 13.8332 14.2377 15.444 14.2377V11.7377C15.2139 11.7377 15.0273 11.5512 15.0273 11.321H12.5273ZM15.444 14.2377H16.5551V11.7377H15.444V14.2377ZM16.5551 14.2377C16.7852 14.2377 16.9718 14.4243 16.9718 14.6544H19.4718C19.4718 13.0435 18.166 11.7377 16.5551 11.7377V14.2377ZM16.9718 14.6544C16.9718 14.8845 16.7852 15.071 16.5551 15.071V17.571C18.166 17.571 19.4718 16.2652 19.4718 14.6544H16.9718ZM16.5551 15.071H13.7773V17.571H16.5551V15.071ZM14.7493 16.321V17.9877H17.2493V16.321H14.7493ZM14.7493 7.98771V9.65438H17.2493V7.98771H14.7493Z" fill="black"/>
	</svg>
)

export default PostPurchaseIcon
