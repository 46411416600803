import { withLDProvider } from 'launchdarkly-react-client-sdk'

// We use the same user key for every initialization and then replace that with the actual user key when you know who the user is.
// This way LaunchDarkly counts the initialization user key only once against our MAU.
const anonymousUser = { key: 'aa0ceb', anonymous: true }

const initLaunchDarkly = (component) =>
    withLDProvider({
        clientSideID: import.meta.env.VITE_TC_LAUNCH_DARKLY_CLIENT_ID,
        flags: {
            // Must add any new feature flag to this list with a default value.
            'insights-pro': false,
            'limited-access': false,
            'capture-kit': false,
            create: false,
            'custom-blocks': false,
            'custom-blocks-v2': false,
            'new-push-page': false,
            'app-clips': false,
            badges: false,
            'push-explorer-relevance-period': 4,
            'custom-push': false,
            'insights-pro-polaris': false,
            'dynamic-product-settings': false,
            'custom-variant-picker': false,
            'custom-push-deep-linking': false,
            'push-i-phone-preview-style': true,
            'new-navigation': false,
            'account-page': false,
            'self-service-icons': false,
            'nosto-grid-block': false,
            'brand-kit': false,
            'push-tap-gpt': false,
            'read-all-orders-scope-banner': false,
            'abandoned-cart-campaign': false,
            'automated-push-chart': false,
            'custom-screen-page': false,
            'chargebee-modal': false,
            'custom-cart-page': false,
            'downgrades-v2': false,
            'pdp-upgrades': false,
            'collection-customer-metafields': false,
            'data-opt-out-destination': false,
            'pbp-addons': false,
            'user-gating-cart': false,
            'post-purchase-campaign': false,
            'winback-campaign': false,
            'aspect-ratios': false,
            'drops-preview': false,
            'navigation-translations': false,
            'block-based-screens': false,
            'mobile-download-banner-migration': false,
            'insights-pro-session-data-migration': false,
            'partner-blocks-bank': false,
            'downgrade-integration-release': false,
            'color-control-enabled': false,
            'app-studio-plp': false,
            'app-studio-pdp': false,
            'app-studio-cart': false,
            'app-studio-home': false,
            'app-studio-account': false,
            'app-studio-editor': false,
            'app-studio-search': false,
            'insights-pro-order-webhook-data-migration': false,
            'top-nav-bar-redesign': false,
            'self-service-fonts-enhancements': false,
            'self-service-icons-enhancements': false,
            'app-studio-native-rendering': false,
            'app-studio-navigation': false,
            'app-studio-conditional-tagging': false,
            'polaris-for-push-explorer': false,
            'app-studio-custom-screens': false,
            'app-studio-show-legacy-account-page': false,
            'app-studio-show-legacy-cart-page': false,
            'app-studio-show-legacy-collections-page': false,
            'block-app-studio-custom-screen-migration': false,
            'order-tracking-campaign': false,
            'app-studio-post-purchase': false,
            'allow-legacy-screen-routing-from-appstudio': false,
        },
        user: anonymousUser,
    })(component)

export default initLaunchDarkly
