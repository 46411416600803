import React from 'react'

const OrderTrackingIcon = ({ color = '#111111', iconLeft }) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
		style={{ marginLeft: iconLeft ? '10px' : 0 }}
	>
		<g clipPath="url(#clip0_1537_70939)">
			<path d="M6.66675 22.6667C6.66675 23.3739 6.9477 24.0522 7.4478 24.5523C7.94789 25.0524 8.62617 25.3333 9.33341 25.3333C10.0407 25.3333 10.7189 25.0524 11.219 24.5523C11.7191 24.0522 12.0001 23.3739 12.0001 22.6667C12.0001 21.9594 11.7191 21.2811 11.219 20.781C10.7189 20.281 10.0407 20 9.33341 20C8.62617 20 7.94789 20.281 7.4478 20.781C6.9477 21.2811 6.66675 21.9594 6.66675 22.6667Z" stroke="#111111" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M20 22.6667C20 23.3739 20.281 24.0522 20.781 24.5523C21.2811 25.0524 21.9594 25.3333 22.6667 25.3333C23.3739 25.3333 24.0522 25.0524 24.5523 24.5523C25.0524 24.0522 25.3333 23.3739 25.3333 22.6667C25.3333 21.9594 25.0524 21.2811 24.5523 20.781C24.0522 20.281 23.3739 20 22.6667 20C21.9594 20 21.2811 20.281 20.781 20.781C20.281 21.2811 20 21.9594 20 22.6667Z" stroke="#111111" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M6.66675 22.6667H4.00008V17.3333M2.66675 6.66666H17.3334V22.6667M12.0001 22.6667H20.0001M25.3334 22.6667H28.0001V14.6667M28.0001 14.6667H17.3334M28.0001 14.6667L24.0001 7.99999H17.3334" stroke="#111111" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4 12H9.33333" stroke="#111111" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_1537_70939">
				<rect width="32" height="32" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default OrderTrackingIcon