/* eslint-disable no-param-reassign */
import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import { RootState } from 'store'
import { automatedPushArray } from 'store/_schemas'
import { normalize } from 'normalizr'
import automatedPushCopy from './automated-push-copy'
import { createEntityAdapter } from '@reduxjs/toolkit'
import getHeaders from 'store/_utils/get-headers'

const automatedPushAdapter = createEntityAdapter({
    selectId: (automatedPush) => automatedPush.job_type,
})

const automatedPushURL = `${
    import.meta.env.VITE_TC_BACKEND_API as string
}/automations`

interface AutomatedPushtate {
    entities: Record<string, never>
    ids: Array<string>
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    initialized: boolean
    couldNotConnect: boolean
}

export type JobTypes =
    'abandon_cart'
    | 'win_back'
    | 'post_purchase'
    | 'welcome_push'
    | 'order_confirmed'
    | 'order_shipped'
    | 'order_out_for_delivery'
    | 'order_delivered'


type Campaign = {
    id: string
    title: string
    app_id: string
    segment_id: string
    job_type: string
    trigger: string
    enabled: boolean
    config: {
        push: {
            send_at: string
            type: string
            message: string
            title: string
            attachment: string
            notification_id: string
            segment_id: string
            destination: {
                type: string
                url: string
            }
            productId: string
            collectionId: string
        }
        send_delay: string
    }
}

const initialState: AutomatedPushtate | unknown =
    automatedPushAdapter.getInitialState({
        loading: 'idle',
        error: null,
        initialized: false,
        couldNotConnect: false,
    })

export const createAutomatedPush = createAsyncThunk(
    'automatedPush/create',
    async (data: Campaign, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()
        const response = await fetch(`${automatedPushURL}/${appId}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { ...headers, 'Content-Type': 'application/json' },
        })
        const responseData = await response.json()
        return responseData
    }
)

export const readAutomatedPushCurrent = createAsyncThunk(
    'automatedPush/readCurrent',
    async (_, { getState }) => {
        // TODO: Uncomment when API is ready
        const headers = await getHeaders()
        const state = getState() as RootState
        const appId = state.app.data.id
        const response = await fetch(`${automatedPushURL}/${appId}/current`, {
            method: 'GET',
            headers: { ...headers, 'Content-Type': 'application/json' },
        })
        const data = await response.json()
        // Filter out empty objects
        const filteredData = data.filter(
            (item) => Object.keys(item).length !== 0
        )
        const normalizedData = normalize(filteredData, automatedPushArray)
        // Return normalized data or empty object if no campaigns
        return normalizedData.entities.automatedPushCampaigns || {}
    }
)

export const updateAutomatedPush = createAsyncThunk(
    'automatedPush/update',
    async (data: Campaign, { getState }) => {
        // TODO: Uncomment when API is ready
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()

        const response = await fetch(
            `${automatedPushURL}/${appId}/${data.id}`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { ...headers, 'Content-Type': 'application/json' },
            }
        )
        const responseData = await response.json()
        return responseData
    }
)

// Automated pushes aren't deleted from the backend, enabled is set to false
export const disableAutomatedPush = createAsyncThunk(
    'automatedPush/delete',
    async (data: Campaign, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getHeaders()
        const response = await fetch(
            `${automatedPushURL}/${appId}/${data.id}`,
            {
                method: 'PUT',
                body: JSON.stringify(data),
                headers: { ...headers, 'Content-Type': 'application/json' },
            }
        )
        const responseData = await response.json()
        return responseData
    }
)

export const automatedPushSlice = createSlice({
    name: 'automatedPush',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createAutomatedPush.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(createAutomatedPush.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.initialized = true
            automatedPushAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(createAutomatedPush.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })
        builder.addCase(readAutomatedPushCurrent.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(
            readAutomatedPushCurrent.fulfilled,
            (state, { payload }) => {
                state.loading = 'idle'
                state.initialized = true
                state.couldNotConnect = false
                // if payload is not an empty object, use setAll, else use removeAll
                if (Object.keys(payload).length > 0) {
                    automatedPushAdapter.setAll(state, payload)
                } else {
                    automatedPushAdapter.removeAll(state)
                }
            }
        )
        builder.addCase(readAutomatedPushCurrent.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
            state.couldNotConnect = true
        })
        builder.addCase(updateAutomatedPush.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(updateAutomatedPush.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.initialized = true
            automatedPushAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateAutomatedPush.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })
        builder.addCase(disableAutomatedPush.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(disableAutomatedPush.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.initialized = true
            automatedPushAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(disableAutomatedPush.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })
    },
})

export const {
    selectAll: selectAllAutomatedPush,
    selectById: selectCampaignByType,
    selectIds: selectAutomatedPushIds,
} = automatedPushAdapter.getSelectors((state: RootState) => state.automatedPush)

export const selectAutomatedPushLoading = (state: RootState): boolean =>
    state.automatedPush.loading === 'pending'

export const selectAutomatedPushError = (
    state: RootState
): SerializedError | null => state.automatedPush.error

export const selectAutomatedPushInitialized = (state: RootState): boolean =>
    state.automatedPush.initialized

export const selectCouldNotConnect = (state: RootState): boolean =>
    state.automatedPush.couldNotConnect

export const selectCampaignsExist = (state: RootState): boolean =>
    selectAllAutomatedPush(state).length > 0

export const selectEnabledCampaigns = (state: RootState): Array<Campaign> =>
    selectAllAutomatedPush(state).filter((campaign) => campaign.enabled)

// Use the selectAll selector to get all campaigns and then filter the enabled ones
export const listEnabledCampaigns = (state: RootState): Array<string> => {
    const enabledCampaigns = selectAllAutomatedPush(state)
        .filter((campaign) => campaign.enabled)
        .map((campaign) => campaign.job_type)

    return enabledCampaigns
}

// The total set of automated push campaigns 'job_type' values
// If a campaign has never been activated, it will not return from the API so it's necessary to save it here
// This value needs to match the list of campaigns in the automated-push-copy.ts file so we will derive it from the keys of that object
export const automatedPushCampaignTypes = Object.keys(automatedPushCopy)

// Inactive campaigns are campaigns that have been enabled at some point but are currently paused + campaigns that have never been enabled
export const listInactiveCampaigns = (state: RootState): Array<string> => {
    const pausedCampaigns = selectAllAutomatedPush(state)
        .filter((campaign) => !campaign.enabled)
        .map((campaign) => campaign.job_type)

    const enabledCampaigns = listEnabledCampaigns(state)
    const neverEnabledCampaigns = automatedPushCampaignTypes.filter(
        (campaign) => !enabledCampaigns.includes(campaign)
    )

    // Using Set to remove duplicates
    return [...new Set([...pausedCampaigns, ...neverEnabledCampaigns])]
}

// Which property of the config should be displayed in the "Delay" section of the CampaignSettings component
export const delayProperty = {
    abandon_cart: 'cart_age',
    welcome_push: 'send_delay',
    win_back: 'send_delay',
    post_purchase: 'send_delay',
}

export const selectDelay = (jobType: JobTypes): string => {
    const campaignCopy = automatedPushCopy[jobType]
    const property = delayProperty[jobType]
    const delay = campaignCopy.data.config[property]
    return delay
}

export default automatedPushSlice.reducer
